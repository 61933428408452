const jhonData = {
  name: "Kennedy",
  // name2: "Richard Tea.",
  // name3: "Alexa Johnson",
  role: "Front End Developer,",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "Facebook",
      url: "https://www.facebook.com/dumebi.ohiri",
      className: "social_facebook",
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/ohiri-chidumebi-474667182/",
      className: "social_linkedin",
    },
    // {
    //   name: "Github",
    //   url: "https://github.com/DennekJr",
    //   className: "fab fa-github",
    // },
  ],
  aboutme: "About Me",
  aboutdetails:
    "I am Kennedy, A Full Stack Software developer. I specialize in Front end development with 2 years experience in a professional environment, a year experience in full stack development and 2 years on personal projects",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "I worked on the front end of a variety of web and mobile applications",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails:
        "Through the course of the past two years, I worked as a Front end developer with minor backend tasks at Bit Space Development and a NextJs and Typescript Developer at EQ3",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Marketing",
      sDetails:
        "I am a Full Stack Developer, specialising on the Front end",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "I plan to continue improving my skills in NextJs, Javascript, React and TypeScript and any new technologies",
    },
    // {
    //   id: 5,
    //   iconName: "icon-gears",
    //   serviceTitle: "Support",
    //   sDetails:
    //     "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    // },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails:
        "My experience is greatest in React/Typescript/NextJs, with 2 years of c#.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: Winnipeg Manitoba. Canada",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: +1.431.556.8309",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: ohirikennedy@yahoo.com",
        },
        {
          id: 4,
          icon: "social_linkedin",
          text: "https://www.linkedin.com/in/ohiri-chidumebi-474667182/",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I take pride in tackling increasingly difficult problems above my skill set to help promote growth",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begins with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer developer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with a unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimization.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
};

export default jhonData;
