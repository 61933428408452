import React, { Component } from "react";
import Sectiontitle from "../Banner/Sectiontitle";
import BlogItems from "../Blog/BlogItems";
import Reveal from "react-reveal/Reveal/";
class Blog extends Component {
  render() {
    return (
      <section className="blog_area" id="blog">
        <div className="container">
          <Sectiontitle
            Title="My Blogs"
            TitleP="Blogs written from research done while studying and from projects done"
          />
          <Reveal effect="fadeInUp" duration={1000}>
            <div className="row" style={{justifyContent: "space-evenly"}}>
              <BlogItems
                bTitle="Guessing Game"
                bDetails="Guessing game program explained in steps, starting from the
                variables defined."
                btnText="Read More"
                image="guess.png"
                Pdata="Oct 21"
                pLink = "https://medium.com/@ddypgnphm/guessing-game-3f2167c1e8ad"
              />
              <BlogItems
                bTitle="SSL"
                bDetails="SSL a cryptographic protocol designed to
                provide communications security over a computer network."
                btnText="Read More"
                image="ssl-blog.png"
                Pdata="Sept 18"
                pLink = "https://medium.com/@ddypgnphm/ssl-c98c4a3786a9"
              />
            </div>
          </Reveal>
        </div>
      </section>
    );
  }
}
export default Blog;
